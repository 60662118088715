import { default as redirect_45assistantjp2X0aNB9eMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue?macro=true";
import { default as pendingnesQwqlHVwMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending.vue?macro=true";
import { default as affiliatejVaqTAFtrQMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/affiliate.vue?macro=true";
import { default as ansvarligt_45spiltlcF7CI0swMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue?macro=true";
import { default as redirect_45assistantjCy7CvbjlJMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue?macro=true";
import { default as bankosiVhMBGwtYMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/banko.vue?macro=true";
import { default as betingelserGRnNYJGs4QMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/betingelser.vue?macro=true";
import { default as bingo5ZPyESiAMuMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/bingo.vue?macro=true";
import { default as BlogMlGDoLCBdRMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/Blog.vue?macro=true";
import { default as bonuskodeRgeVYoFkGFMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/bonuskode.vue?macro=true";
import { default as casinocJl4Xud8UKMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/casino.vue?macro=true";
import { default as cookiepolitikumCDo4uEzaMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/cookiepolitik.vue?macro=true";
import { default as FAQNXjAZTnnghMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/FAQ.vue?macro=true";
import { default as _91slug_93U20rJA96poMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue?macro=true";
import { default as indexMxLxuzrvBzMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/index.vue?macro=true";
import { default as kundeserviceIUFELB31YvMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/kundeservice.vue?macro=true";
import { default as om_45osFoFFl6ixXiMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/om-os.vue?macro=true";
import { default as presseBPkaSaexHuMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/presse.vue?macro=true";
import { default as privatlivspolitikoTSB4TYG5dMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/privatlivspolitik.vue?macro=true";
import { default as signupcyFNdhq76ZMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/signup.vue?macro=true";
import { default as spilleautomaterYl6P3PXfWqMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/spilleautomater.vue?macro=true";
import { default as spiloversigtRufJsu4yLLMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/spiloversigt.vue?macro=true";
import { default as _91seoName_93TLQewWDtadMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/spilside/[seoName].vue?macro=true";
import { default as tvcqoswkyJ4mMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/tv.vue?macro=true";
import { default as vindereRXMCdpr8WwMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/vindere.vue?macro=true";
export default [
  {
    name: "redirect-assistant",
    path: "/payments/redirect-assistant",
    meta: redirect_45assistantjp2X0aNB9eMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue").then(m => m.default || m)
  },
  {
    name: "pending",
    path: "/payments/pending",
    meta: pendingnesQwqlHVwMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending.vue").then(m => m.default || m)
  },
  {
    name: "affiliate",
    path: "/affiliate",
    meta: affiliatejVaqTAFtrQMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: "ansvarligt-spil",
    path: "/ansvarligt-spil",
    meta: ansvarligt_45spiltlcF7CI0swMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue").then(m => m.default || m)
  },
  {
    name: "app-redirect-assistant",
    path: "/app/redirect-assistant",
    meta: redirect_45assistantjCy7CvbjlJMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue").then(m => m.default || m)
  },
  {
    name: "banko",
    path: "/banko",
    meta: bankosiVhMBGwtYMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/banko.vue").then(m => m.default || m)
  },
  {
    name: "betingelser",
    path: "/betingelser",
    meta: betingelserGRnNYJGs4QMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/betingelser.vue").then(m => m.default || m)
  },
  {
    name: "bingo",
    path: "/bingo",
    meta: bingo5ZPyESiAMuMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/bingo.vue").then(m => m.default || m)
  },
  {
    name: "Blog",
    path: "/Blog",
    meta: BlogMlGDoLCBdRMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/Blog.vue").then(m => m.default || m)
  },
  {
    name: "bonuskode",
    path: "/bonuskode",
    meta: bonuskodeRgeVYoFkGFMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/bonuskode.vue").then(m => m.default || m)
  },
  {
    name: "casino",
    path: "/casino",
    meta: casinocJl4Xud8UKMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/casino.vue").then(m => m.default || m)
  },
  {
    name: "cookiepolitik",
    path: "/cookiepolitik",
    meta: cookiepolitikumCDo4uEzaMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/cookiepolitik.vue").then(m => m.default || m)
  },
  {
    name: "FAQ",
    path: "/FAQ",
    meta: FAQNXjAZTnnghMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/FAQ.vue").then(m => m.default || m)
  },
  {
    name: "games-entrance-slug",
    path: "/games/entrance/:slug()",
    meta: _91slug_93U20rJA96poMeta || {},
    alias: ["/spil/:slug","/spilleautomater/entrance/:slug"],
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexMxLxuzrvBzMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kundeservice",
    path: "/kundeservice",
    meta: kundeserviceIUFELB31YvMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/kundeservice.vue").then(m => m.default || m)
  },
  {
    name: "om-os",
    path: "/om-os",
    meta: om_45osFoFFl6ixXiMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/om-os.vue").then(m => m.default || m)
  },
  {
    name: "presse",
    path: "/presse",
    meta: presseBPkaSaexHuMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/presse.vue").then(m => m.default || m)
  },
  {
    name: "privatlivspolitik",
    path: "/privatlivspolitik",
    meta: privatlivspolitikoTSB4TYG5dMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/privatlivspolitik.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupcyFNdhq76ZMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "spilleautomater",
    path: "/spilleautomater",
    meta: spilleautomaterYl6P3PXfWqMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/spilleautomater.vue").then(m => m.default || m)
  },
  {
    name: "spiloversigt",
    path: "/spiloversigt",
    meta: spiloversigtRufJsu4yLLMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/spiloversigt.vue").then(m => m.default || m)
  },
  {
    name: "spilside-seoName",
    path: "/spilside/:seoName()",
    meta: _91seoName_93TLQewWDtadMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/spilside/[seoName].vue").then(m => m.default || m)
  },
  {
    name: "tv",
    path: "/tv",
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/tv.vue").then(m => m.default || m)
  },
  {
    name: "vindere",
    path: "/vindere",
    meta: vindereRXMCdpr8WwMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/vindere.vue").then(m => m.default || m)
  }
]